import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import '../styles/index.scss'
import '../styles/_responsive.scss'
import { library } from '@fortawesome/fontawesome-svg-core'
import {  } from '@fortawesome/free-brands-svg-icons'
import { faLongArrowAltRight, faLongArrowAltLeft, faTimesCircle, faAngleDown, faPhone } from '@fortawesome/free-solid-svg-icons'
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons'


var prefix_string = "en";

library.add(faLongArrowAltRight, faLongArrowAltLeft, faTimesCircle, faAngleDown, faPhone, faCheckSquare)


const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
            image
          }
        }
      }
    `}
    render={({ site: { siteMetadata: seo } }) => {
      const image = seo.image;
      return(
        <>
    {/*  <Helmet>
       <title>WELL - Digital Marketing, Websites, Google Ads, Social Media</title>
        <meta property="og:image" content={image} />
        <meta property="og:image:type" content="image/jpg" />
        <meta property="og:type" content='website' />
        <meta property="og:description" content='Web and Digital Marketing Agency' />
        <meta property="og:url" content='https://wellpromotion.ba' />
        <meta name="twitter:card" content={image} />
    <meta property="twitter:image:alt" content='Well Business Promotion Marketing Agency' /> 
          <html lang={prefix_string} />
      </Helmet> */}
        <div>
          {children}
        </div>
      </>
 );
 }}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout